import React, { FC, ReactNode, useLayoutEffect, useState } from 'react';
import Header from './header';


const Layout: FC<{
    className?: string;
    children: ReactNode;
}> = (props) => {
    const [screen, setScreen] = useState({ width: window.innerWidth, height: window.innerHeight });

    useLayoutEffect(() => {
        setScreen({ width: window.innerWidth, height: window.innerHeight });
        const onResizeHero = () => {
            setScreen({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', () => { onResizeHero() });
        return window.removeEventListener('resize', () => { onResizeHero() });
    }, []);
    return (
        <div id='page-wrapper'>
            <Header />
            <main 
                id='PageContent'
                className={'page-content ' + (props.className || '')}
                style={{
                    height: screen.height + "px"
                }}>
                {props.children}
            </main>
            <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.min.css'/>
            {/* <Footer /> */}
        </div>
    );
};

export default Layout;
