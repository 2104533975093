import React, { FC, useState } from "react";
import "./playground.css";


// interface Props {
//     imgSrc: string;
//     className?: string;
// }

// const Playground: FC<Props> = (props) => {
//     return (
//         <div id="playground" className="playground">
//             <Item {...props} />
//             <Item {...props} />
//         </div>
//     );
// };

// const Item: FC<Props> = (props) => {
//     const { imgSrc, className } = props;
    
//     const dropping = useRef<number>();
//     const timeout = useRef<number>();

//     const [clicked, setClicked] = useState(false);
//     const [moves, setMoves] = useState(false);
//     const [dropped, setDropped] = useState(false);
//     const [pointerDown, setPointerDown] = useState(false);
//     const [movement, setMovement] = useState({
//         x: 0,
//         y: 0
//     });
//     const [translate, setTranslate] = useState({
//         x: 0,
//         y: 0
//     });

//     const handlePointerDown = (e: PointerEvent<HTMLElement>) => {
//         e.preventDefault();
//         e.stopPropagation();

//         window.clearInterval(dropping.current);
//         window.clearTimeout(timeout.current);

//         setMoves(false);
//         setPointerDown(true);
//         setDropped(false);
//     };

//     useEffect(() => {
//         if (dropped) {
//             let movements = movement
//             dropping.current = window.setInterval(() => {
//                 setTranslate((e) => ({
//                     x: e.x + movements.x * .98,
//                     y: e.y + movements.y * .98
//                 }));
//                 movements = {
//                     x: movements.x * .98,
//                     y: movements.y * .98
//                 }
//             }, 5)
//             timeout.current = window.setTimeout(() => {
//                 return clearInterval(dropping.current);
//             }, 2000);

//             return () => {
//                 window.clearInterval(dropping.current);
//                 window.clearTimeout(timeout.current)
//             }
//         }
//     }, [dropped, movement]);

//     useEffect(() => {
//         console.log("pointerDown", pointerDown)
//         const playground = document.getElementById("playground");
//         if (pointerDown) {
//             playground!.addEventListener("pointermove", (e) => {
//                 if (pointerDown) {
//                     setMoves(true);
//                     setTranslate((a) => ({
//                         x: a.x + e.movementX,
//                         y: a.y + e.movementY
//                     }));
//                     setMovement({
//                         x: e.movementX,
//                         y: e.movementY
//                     });
//                 }
//             });

//             playground!.addEventListener("pointerup", (e) => {
//                 setPointerDown(false);
//                 setDropped(true);
//                 console.log("mouseUP")
//                 playground!.removeEventListener("pointermove", (e) => {
//                     if (pointerDown) {
//                         setMoves(true)
//                         setTranslate((a) => ({
//                             x: a.x + e.movementX,
//                             y: a.y + e.movementY
//                         }));
//                         setMovement({
//                             x: e.movementX,
//                             y: e.movementY
//                         });
//                     }
//                 });
//             });
            
//         }
//         else {
            
//             playground!.removeEventListener("pointerup", (e) => {
//                 setPointerDown(false);
//                 setDropped(true);
//             });
//             playground!.removeEventListener("pointermove", (e) => {
//                 if (pointerDown) {
//                     setMoves(true);
//                     setTranslate((a) => ({
//                         x: a.x + e.movementX,
//                         y: a.y + e.movementY
//                     }));
//                     setMovement({
//                         x: e.movementX,
//                         y: e.movementY
//                     });
//                 }
//             });
//         }


//         return () => {
//             playground!.removeEventListener("pointermove", (e) => {

//                 if (pointerDown) {
//                     setMoves(true)
//                     setTranslate((a) => ({
//                         x: a.x + e.movementX,
//                         y: a.y + e.movementY
//                     }));
//                     setMovement({
//                         x: e.movementX,
//                         y: e.movementY
//                     });
//                 }
//             });

//             playground!.removeEventListener("pointerup", (e) => {
//                 setPointerDown(false);
//                 setDropped(true);
//             });
//         };
//     }, [pointerDown]);
//     return (
//         // <div 
//         //     className="playground"
//         //     onPointerMove={(e) => { handlePointerMove(e) }}
//         //     onPointerUp={() => { handlePointerUp() }}
//         //     onMouseLeave={() => { handlePointerUp() }}>
//             <div className="item pointer-events-none">
//                 <div 
//                     className={"draggable " + className}
//                     style={{
//                         transform: `translate3d(${translate.x}px, ${translate.y}px, 0px)`
//                     }}>
//                     <picture 
//                         className={`${clicked && "clicked"}`}
//                         onPointerDown={(e) => { handlePointerDown(e) }}
//                         onClick={() => { !moves && setClicked(true) }}>
//                         <img 
//                             alt="cloud"
//                             src={imgSrc}
//                             className="w-full h-full" />
//                     </picture>
//                 </div>
//             </div>
//         // </div>
//     );
// };



const Playground: FC = () => {
    return (
        <div className="absolute top-0 left-0 w-full h-full">
            <AnimatedCloud num={1} imgSrc="/images/cloud_3.svg" />
            <AnimatedCloud num={2} imgSrc="/images/cloud_3.svg" />
            <AnimatedCloud num={3} imgSrc="/images/cloud_3.svg" />
            <AnimatedCloud num={4} imgSrc="/images/cloud_2.svg" />
            <AnimatedCloud num={5} imgSrc="/images/cloud_1.svg" />
            <AnimatedCloud num={6} imgSrc="/images/cloud_2.svg" />
        </div>
    );
};

const AnimatedCloud: FC<{ num: number, imgSrc: string }> = (props) => {
    const { num, imgSrc } = props;
    const [clicked, setClicked] = useState(false);

    return (
        <div
            style={{ "animationDelay": (Math.random()*2000) + "ms"}} 
            className={`absolute cloud cloud--${num}`}>
            <picture
                className={`${clicked ? "clicked" : ""}`}
                onClick={() => { setClicked(true) }}>
                <img 
                    draggable={false}
                    alt="cloud"
                    src={imgSrc}
                    className="w-full h-full" />
            </picture>
        </div>
    );
};

export default Playground;