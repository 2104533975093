import { FC } from "react";
import Layout from "../../default-layout/default";
import Footer from "../../default-layout/footer";
import "./privacy.css";

const Privacy: FC = () => {
    return (
        <Layout>
            <div className="flex flex-col relative bg-altGrey mt-9">
                <div className="flex flex-col py-24 px-4 text-secondary md:max-w-screen-md xl:max-w-screen-xl mx-auto">
                    <h1 className="text-5xl font-bold mb-16">Privacy policy</h1>
                    <p className="mb-4">
                        This Privacy Policy is designed to assist you with understanding how O21 ("O21", "we", "us" or "our") collect, use and disclose the information you provide us when accessing or using the website, O21.io 
                    </p>

                    <h3 className="text-2xl mb-2">1. CHANGES TO OUR PRIVACY POLICY</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        We may change this Privacy Policy in our sole discretion at any time. Any revisions to this Privacy Policy will be posted on the home page of our Website. It is your obligation to periodically visit our Website to review any changes that may be made to this Privacy Policy. Your continued use of our Website constitutes your agreement to be bound by any such changes to this Privacy Policy. If you do not agree with this Privacy Policy, you are not authorized to use our Website and your sole recourse is to not use our Website. 
                    </p>

                    <h3 className="text-2xl mb-2">2. WHAT INFORMATION DO WE COLLECT?</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        When you visit our Website, you may provide us with two types of information: personal information that you consent to disclose and that is collected and processed on an individual basis; and information collected automatically when you use our Website or the services available on our Website (collectively, the “Information”). Please note that you have the right to withdraw your consent at any time. 
                    </p>

                    <h3 className="text-2xl mb-2">3. WHAT IS PERSONAL INFORMATION?</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        Personal information is collected only when voluntarily offered and solely for purposes that are clearly identified on our Website. Personal information means any information relating to an identified or identifiable person, like you. It includes, but is not limited to your name, identification number, location data, online identifier, or any other information relating to your physical, physiological, genetic, mental, economic, cultural or social identity. 
                        <br />
                        <br />
                        By providing us with your personal information, you also consent for us to collect, hold, use and disclose your personal information in accordance with this Privacy Policy. In addition to providing the foregoing information, if you choose to correspond further with us through e-mail or through the “contact” section of our Website, we may retain, use and disclose the content of your messages together with your e-mail address and our responses. 
                    </p>

                    <h3 className="text-2xl mb-2">4. EU-BASED USERS PERSONAL INFORMATION</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        Pursuant to EU General Data Protection Regulation 2016/679 (GDPR):
                        <br />
                        <br />
                        <ul className="marker:text-secondary list-disc mb-4 pl-8 ">
                            <li>
                                Your contact at O21 for issues related to the processing of your Information will be please contact us
                            </li>
                            <li>
                                Your Information will be stored for a period of 19 months;
                            </li>
                            <li>
                                You shall have the right to request from O21 access to and rectification or erasure of personal data or restriction of processing concerning the data subject to or object to processing as well as the right to data portability; 
                            </li>
                            <li>
                                You shall have the right to lodge a complaint with your supervisory authority.
                            </li>
                        </ul>
                        You are providing your personal Information to fulfill the requirement necessary to enter into a contract with us and access the products or services offered on our Website. Failure to provide such Information will preclude you from accessing the products or services offered on our Website. 
                    </p>

                    <h3 className="text-2xl mb-2">5. PURPOSE AND USE OF INFORMATION</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        We may process your personal information by collecting, recording, organising, structuring, storing, adapting, retrieving, consulting, using, or disclosing the Information. We will only use the Information for purposes of: 
                        <br />
                        <br />
                        <ul className="marker:text-secondary list-disc mb-4 pl-8 ">
                            <li>
                                providing, maintaining, delivering or improving our Website or the products or services provided through our Website;
                            </li>
                            <li>
                                analyzing and tracking data to determine the usefulness or popularity of certain content and to better understand the online activity of our Website users;
                            </li>
                            <li>
                                fulfilling our legal or regulatory requirements;
                            </li>
                            <li>
                                providing you with the information or products or services that you have requested;
                            </li>
                            <li>
                            answering your inquiry or responding to a communication from you;
                            </li>
                            <li>
                            developing new products or services;
                            </li>
                            <li>
                            sending you technical notices, support or administrative notifications;
                            </li>
                            <li>
                            communicating with you about news, products, services, events and other information we think will be of interest to you;
                            </li>
                            <li>
                            matching your Information with job vacancies to assist us in finding a position that is most suitable for you if you have contacted us about recruitment;
                            </li>
                            <li>
                            monitoring and analyzing trends, usage and activities in connection with our Website;
                            </li>
                            <li>
                            detecting, investigating and preventing fraudulent transactions or unauthorized or illegal activities;
                            </li>
                            <li>
                            protecting the rights and property of the Parties (as defined below) and others;
                            </li>
                            <li>
                            linking, connecting or combining Information we collect from or about you with other Information; and
                            </li>
                            <li>
                            carrying out any other purpose or reason for which the Information was collected.
                            </li>
                        </ul>
                        Should we intend to further process the Information for a purpose other than that listed in the present article, we shall inform you on that other purpose and with any other relevant information, prior to that further processing. 
                    </p>

                    <h3 className="text-2xl mb-2">6. SHARING OF INFORMATION</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        We will only share Information about you in the following ways: 
                        <br />
                        <br />
                        <ul className="marker:text-secondary list-disc mb-4 pl-8 ">
                            <li>with your consent or at your instruction;</li>
                            <li>with our current or future parent companies, affiliates, subsidiaries and with other companies under common control or ownership with us or our offices internationally;</li>
                            <li>with third parties or service providers that perform work for us;</li>
                            <li>certain information you may choose to share may be displayed publicly, such as your username and any content you post when you use interactive areas of our Website like our online forums;</li>
                            <li>in connection with a merger or sale of our company assets, or if we do a financing or are involved in an acquisition or any other situation where Information may be disclosed or transferred as one of our business assets;</li>
                            <li>in response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation or legal process;</li>
                            <li>if we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property and safety of any Party or others; and</li>
                            <li>with third parties where aggregate Information is disclosed which cannot be reasonably be used to identify you.</li>
                        </ul>
                    </p>

                    <h3 className="text-2xl mb-2">7. SOCIAL SHARING FEATURES</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                        Our Website may offer social sharing features, links to social media and other integrated tools. Your use of such features enables the sharing of information with your contacts or the public, depending on the settings you use with the entity that provides the social sharing feature or social media. Please visit the privacy policies of the entities that provide these features to obtain more information about the purpose and scope of data collection and the processing of such data by such entities. 
                    </p>

                    <h3 className="text-2xl mb-2">8. PROTECTION OF PERSONAL INFORMATION</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                    We take reasonable steps to protect the security of the information communicated through our Website. However, no computer security system is entirely foolproof and the Internet is not a secure method of transmitting information. As a result, we do not assume any responsibility for the data you submit to or receive from us through the Internet or for any unauthorised access or use of that information and we cannot and do not guarantee that information communicated by you to us or sent to you by us will be received or that it will not be altered before or after its transmission to us. You agree to not hold us and our respective past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (collectively, the “Parties”) liable for any loss or damage of any sort incurred as a result of any misappropriation, interception, modification, deletion, destruction or use of information provided through our Website. 
                    <br/><br/>
                    Most web browsers are set to accept cookies as a default. You may wish to opt out by turning off cookies (please refer to the help menu on your browser); however, switching off cookies may restrict your use of our Website. 
                    <br/><br/>
                    You may also opt out of receiving promotional communications from us at any time by following the instructions in those communications. If you opt out, we may still send you non-promotional communications, such as technical notices, support or administrative notifications or information about your account (if you have one). 
                    </p>

                    <h3 className="text-2xl mb-2">9. HYPERLINKS AND THIRD-PARTY SITES</h3>
                    <hr className="border-t border-secondary border-opacity-25 my-4" />
                    <p className="mb-4">
                    This Website may contain links to other third party websites that may collect personal information about you, including through cookies or other technologies. If you link to another website, you will leave this Website and this Privacy Policy will not apply to your use of and activity on those other websites. You should consult these other websites' privacy policies as we have no control over them and are not responsible for any information that is submitted to or collected by these third parties. 
                    </p>
                </div>
                <div className="flex justify-center mb-20 md:hidden">
                    <a
                        href="/"
                        style={{
                            width: '250px',
                            height: '45px'
                        }}>
                        <img 
                            alt='logo'
                            src={'/images/logo2.svg'}
                            className='w-full h-full' />
                    </a>
                </div>
                <Footer />
            </div>      
        </Layout>
    );
};

export default Privacy;