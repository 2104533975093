import React from 'react';
import Home from '../home';
import './App.css';
import {
  Route,
  Routes
} from "react-router-dom";
import Privacy from '../privacy';
import Terms from '../terms';
import Register from '../register';


function App() {
  

  return (
    <Routes>
      <Route path='/register' element={<Register />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/' element={<Home />} />
    </Routes>
  );
}

export default App;
