import { useEffect } from "react";

const Register = () => {

    useEffect(() => {
        (function () { 
            var e = "ss-widget", t = "script", a = document, r = window; 
            var s, n, c; 
            r.SS_WIDGET_TOKEN = "tt-f7ceff"; 
            r.SS_ACCOUNT = "obvious21.surveysparrow.com"; 
            r.SS_SURVEY_NAME = "Funderz---Register"; 
            if (!a.getElementById(e)) { 
                var S = function () { S.update(arguments) }; 
                S.args = []; 
                S.update = function (e) { S.args.push(e) }; 
                r.SparrowLauncher = S; 
                s = a.getElementsByTagName(t); 
                c = s[s.length - 1]; 
                n = a.createElement(t); 
                n.type = "text/javascript"; 
                n.async = !0; 
                n.id = e; 
                n.src = ["https://", "obvious21.surveysparrow.com/widget/", r.SS_WIDGET_TOKEN].join(""); 
                c.parentNode.insertBefore(n, c) 
            } 
        })();
    }, [])
    return (
        <div className="flex w-screen h-screen">
            <div id="ss_survey_widget"></div>
        </div>
    )
}

export default Register;