import { useLayoutEffect, useState } from "react";

import Powered from "../components/powered";

const Footer: React.FC = () => {
    const [screen, setScreen] = useState({ width: 0, height: 0});
    useLayoutEffect(() => {
        setScreen({ width: window.innerWidth, height: window.innerHeight });

        const onResize = () => {
            setScreen({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', () => { onResize() });
        return () => {
            window.removeEventListener('resize', () => { onResize() })
        };
    }, []);

    return (
        <footer id='Footer' className="absolute bottom-0 w-full pb-5 px-4 md:px-10">
            <div className="flex text-xs md:text-sm font-bold text-secondary justify-between w-full items-end">
                <span>
                    © 2022 O21 SAS {screen.width < 768 ? <br/> : "-"} All rights reserved
                </span>
                {
                    screen.width < 768 ?
                    <div className="flex flex-col">
                        <a href="/privacy">Privacy policy</a>
                        <a href="/terms">Terms of use</a>
                    </div>:
                    <>
                        <a href="/privacy">Privacy policy</a>
                        <a href="/terms">Terms of use</a>
                    </>
                }
                
                <Powered dark inFooter={true} />
            </div>
        </footer>
    );
};

export default Footer;