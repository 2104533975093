import React from 'react';
import Cloud from '../../components/cloud';
import Hero from '../../components/hero';
import Layout from '../../default-layout/default';
import "./home.css";

function Home() {
  
  return (
    <Layout className='snap-parent'>
        <Hero className='snap-children' />
        <Cloud className='snap-children' />
    </Layout>
  );
}

export default Home;
