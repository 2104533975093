import React, { 
    FC, 
    useLayoutEffect, 
    useState } from 'react';
import Carousel from './carousel';
import Powered from '../powered';
import './hero.css';
import Slider from './slider';


const Hero: FC<{ className?: string }> = ({ className }) => {
    const [selected, setSelected] = useState(1);
    const [screen, setScreen] = useState({ width: window.innerWidth, height: window.innerHeight });

    const next = () => { 
        setSelected(selected + 1 > 3 ? 1 : selected + 1); 
    };
    const previous = () => { 
        setSelected(selected - 1 === 0 ? 3 : selected - 1); 
    };
    const handleArrow = () => {
        const pageContent = document.body.getElementsByClassName('page-content')[0] as HTMLElement;
        const cloud = document.getElementById('clouds') as HTMLElement;

        pageContent.style.scrollBehavior = 'smooth';
        pageContent.scrollTo({ left: 0, top: cloud.offsetTop });
    }
    useLayoutEffect(() => {
        setScreen({ width: window.innerWidth, height: window.innerHeight });
        const onResizeHero = () => {
            setScreen({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', () => { onResizeHero() });
        return window.removeEventListener('resize', () => { onResizeHero() });
    }, []);

    return (
        <div 
            id="hero"
            className={'flex flex-col md:flex-row items-center justify-between md:justify-center w-full relative ' + className}
            style={{
                height: screen.height + "px"
            }}>
            {screen.width > 768 ? <>
                <div className='absolute w-full flex justify-between z-50 height-without-header bottom-0'>
                    <div className='w-4/12 h-full cursor-pointer' onClick={() => { previous() }} />
                    <div className='w-4/12 h-full flex'>
                        <div className='w-1/2 h-full cursor-pointer' onClick={() => { next() }} /> 
                        <div className='w-1/2 h-full cursor-pointer' onClick={() => { previous() }}/> 
                    </div> 
                    <div className='w-4/12 h-full cursor-pointer' onClick={() => { next() }} />
                </div>
                <Carousel selected={selected} />
                <div 
                    style={{
                        width: "100px",
                        height: "100px",
                        left: "calc(50% - 50px)",
                        zIndex: 51
                    }}
                    className="flex justify-center items-center absolute bottom-5 left-5/12 float-item cursor-pointer"
                    onClick={() => { handleArrow() }}>
                    <img 
                        className='w-full h-full'
                        src='/images/arrow-desktop.svg'
                        alt='arrow'
                    />
                </div>
                <Powered />
            </>:
            <>
                <Slider />
            </>}
        </div>
    );
};


export default Hero;