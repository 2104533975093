import React from "react";

import "./index.css";

const Powered = ({ dark=false, inFooter=false } : { dark?: boolean, inFooter?: boolean }) => {
    
    return (
        <a 
            href="https://o21.io"
            target="_blank"
            rel="noopener noreferrer"
            className={`powered-by ${inFooter && "in-footer"}`}>
            <h1 className={`text-bold ${inFooter ? "text-xs md:text-base" : "text-sm md:text-2xl"} font-bold ${dark ? "text-secondary" : "text-primary"}`}>powered by</h1>
            <div className="o21">
                { dark ? <img 
                    alt="o21"
                    src="/images/o21-dark.svg"
                    className="w-full h-full" />:
                    <img 
                    alt="o21"
                    src="/images/o21.svg"
                    className="w-full h-full" />
                }
            </div>
        </a>
    );
};

export default Powered;