import { FC, useEffect } from "react"


interface Props {
    className?: string;
    selected: number;
}


const Carousel: FC<Props> = (props) => {    
    const { selected } = props;

    useEffect(() => {
        const pageContent = document.body.getElementsByClassName('page-content')[0];

        const handleScroll = (event: Event) => {
            const pageContent = event.target as Element;
            const item1 = document.getElementById('funderz1') as HTMLVideoElement;
            const item2 = document.getElementById('funderz2') as HTMLVideoElement;
            const item3 = document.getElementById('funderz3') as HTMLVideoElement;
        
            const height = window.innerHeight;
            const maxScroll = pageContent.scrollHeight;
            const scrollPosition = pageContent.scrollTop;

            if (maxScroll - scrollPosition - height >= height) {
                if ((maxScroll - scrollPosition - 2 * height) < height / 4) {
                    item1.play();
                    item2.play();
                    item3.play();
                    
                } else {
                    item1.pause();
                    item2.pause();
                    item3.pause();
                }
            }
        }
        pageContent.addEventListener('scroll', handleScroll);   
        return pageContent.removeEventListener('scroll', handleScroll); 
    }, []);

    return (
        <>
            <div 
                className='absolute bottom-0 w-full flex justify-center'
                style={{ height: "100%", pointerEvents: "none" }}>
                <div className={`funderz-container checked-${selected}`}>
                    {
                        Array(3).fill(null).map((_, index) => {
                            return (
                                <div 
                                    id={`item-${index + 1}`} 
                                    key={`item-${index + 1}`} 
                                    className={`card`}>
                                    <video 
                                        id={`funderz${index + 1}`}
                                        preload='auto' 
                                        autoPlay 
                                        loop 
                                        muted 
                                        playsInline
                                        className='h-full'>
                                        <source src={`/images/funderz${index + 1}.mov`} type='video/mp4; codecs="hvc1"' />
                                        <source src={`/images/funderz${index + 1}-crop.webm`} type='video/webm' />
                                    </video>
                                </div>
                            )
                        })
                    }
                    <div className="intra-text">
                        <svg viewBox="0 0 16 3" className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
                            <linearGradient id="gr-simple" x1="0" y1="0" x2="0" y2="100%">
                                <stop stopColor="#68D18D" offset="10%"/>
                                <stop stopColor="#68C2E7" offset="100%"/>
                            </linearGradient>
                            <text x="8" y="2" textAnchor="middle" fontSize="2" fill="none" strokeWidth=".02" stroke="url(#gr-simple)" fontFamily="NB International Pro" fontWeight="bold">COMING SOON</text>
                        </svg>
                        <svg viewBox="0 0 16 3" className="w-full h-full absolute top-0 left-0 neon" xmlns="http://www.w3.org/2000/svg">
                            <linearGradient id="gr-simple" x1="0" y1="0" x2="0" y2="100%">
                                <stop stopColor="#68D18D" offset="10%"/>
                                <stop stopColor="#68C2E7" offset="100%"/>
                            </linearGradient>
                            <text x="8" y="2" textAnchor="middle" fontSize="2" fill="none" strokeWidth=".02" stroke="url(#gr-simple)" fontFamily="NB International Pro" fontWeight="bold">COMING SOON</text>
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Carousel;