import { 
  FC, 
  useLayoutEffect, 
  useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header: FC = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleLogo = () => {
    if (window.location.pathname === "/") {
      const pageContent = document.body.getElementsByClassName('page-content')[0] as HTMLElement;
      const hero = document.getElementById('hero') as HTMLElement;
  
      pageContent.style.scrollBehavior = 'smooth';
      pageContent.scrollTo({ left: 0, top: hero.offsetTop });
    } else {
      navigate("/");
    }
  }

  useLayoutEffect(() => {
    const pageContent = document.body.getElementsByClassName('page-content')[0] as HTMLDivElement;

    const handleResize = () => {
      const pageContent = document.body.getElementsByClassName('page-content')[0] as HTMLDivElement;
      setSize({
        width: window.innerWidth - (pageContent.offsetWidth - pageContent.clientWidth),
        height: window.innerHeight
      })
    }
    
    const handleScroll = (event: Event) => {
      const pageContent = event.target as HTMLDivElement;
      setScrollPosition(pageContent.scrollTop);
    }

    handleResize();

    window.addEventListener("resize", () => { handleResize() });
    pageContent.addEventListener("scroll", handleScroll );
    return () => {
      window.removeEventListener("resize", () => { handleResize() });
      pageContent.removeEventListener("scroll", handleScroll );
    }
  }, []);

  return (
    <header 
      id='Header'
      className='bg-secondary'
      style={{
        width: size.width + 'px'
      }}>
      <div 
        className='flex items-top h-full w-full px-4 md:px-10 bg-secondary justify-between'>
        <div className='flex w-1/3 justify-start items-center'>
          <a  
            id="twitter" 
            className='social-layout'
            href="https://twitter.com/funderzbyo21"
            target="_blank"
            rel="noopener noreferrer">
            <img 
              alt='twitter'
              src='/images/twitter.svg'
              className='w-full h-full'
            />
          </a>
          <a 
            id="discord" 
            className='social-layout ml-3 cursor-default'
            href="#">
            <img 
              alt='discord'
              src='/images/discord.svg'
              className='w-full h-full'
            />
          </a>
        </div>
        <div
          style={{
            width: scrollPosition > (size.height / 2) || window.location.pathname !== "/" ? '250px' : '500px',
            height: scrollPosition > (size.height / 2) || window.location.pathname !== "/" ? '45px' : '90px',
            position: 'relative',
            transition: '0.5s ease-in-out',
            cursor: "pointer"
          }}
          className="hidden md:flex"
          onClick={() => { handleLogo() }}>
          <img 
            alt='logo'
            src={'/images/logo2.svg'}
            className='w-full h-full' />
        </div>
        <div className='flex w-1/3 justify-end items-center'>
          <a href='/register'>
            <button 
              className={`btn-outlined text-primary border-primary duration-500 overflow-hidden`}
              style={{ width: '150px', height: '45px' }}>
                REGISTER
            </button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;