import React, { FC, useLayoutEffect, useState } from 'react'
import Footer from '../../default-layout/footer';
import Playground from '../playground';

import './cloud.css';


const Clouds: FC<{ className?: string; }> = ({ className }) => { 
    const [screen, setScreen] = useState({ width: 0, height: 0});

    useLayoutEffect(() => {
        setScreen({ width: window.innerWidth, height: window.innerHeight });

        const onResize = () => {
            setScreen({ width: window.innerWidth, height: window.innerHeight });
        };

        window.addEventListener('resize', () => { onResize() });
        return () => {
            window.removeEventListener('resize', () => { onResize() })
        };
    }, []);

    return (
        <div 
            id='clouds' 
            className={'flex flex-col  w-full smoke-container items-center justify-center overflow-hidden relative ' + className}
            style={{
                height: screen.height + "px"
            }}>
            <div 
                className='flex items-center md:hidden'
                style={{
                    width: "82%"
                }}>
                <svg viewBox="0 0 6 1.1" className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
                    <text x="3" y="1" textAnchor="middle" fontSize="1" fill="none" strokeWidth=".05" stroke="#343B48" fontFamily="NB International Pro" fontWeight="bold" letterSpacing="-.01">TIME TO ACT !</text>
                </svg>
            </div>
            <div className='flex flex-col mt-8 md:mt-0 px-4 md:px-10 max-w-6xl w-full md:w-4/6 md:h-4/6 justify-center items-center'>
                <div 
                    className='hidden md:flex items-center'
                    style={{
                        width: "32%"
                    }}>
                    <svg viewBox="0 0 6 1.1" className="w-full h-full" xmlns="http://www.w3.org/2000/svg">
                        <text x="3" y="1" textAnchor="middle" fontSize="1" fill="none" strokeWidth=".05" stroke="#343B48" fontFamily="NB International Pro" fontWeight="bold" letterSpacing="-.01">TIME TO ACT !</text>
                    </svg>
                </div>
                <div className='text-secondary font-bold text-3xl md:text-2xl px-6 md:px-24 md:mt-6'>
                    Funderz is the first NFT collection dedicated to learn about climate solutions and engage with O21 ecosystem to finance sustainable projects.
                </div>
            </div>
            <Playground /> 
            <Footer />
        </div>
    )
  }

export default Clouds;
